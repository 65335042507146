import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/Preloader";
import TopBar from "../../components/TopBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Footer from "../../components/Footer";

const PaymentDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailsChanged, setDetailsChanged] = useState(false);
  const [pinModalOpen, setPinModalOpen] = useState(true);
  const [pin, setPin] = useState("");
  const [usdtAddress, setUsdtAddress] = useState("");
  const [btcAddress, setBtcAddress] = useState("");
  const [ethAddress, setEthAddress] = useState("");
  const [bnbAddress, setBnbAddress] = useState("");
  const [usdtNetwork, setUsdtNetwork] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const getPaymentDetails = async () => {
      const { data } = await axios.get(
        "https://api.bountytrade.com/api/payment-details"
      );

      setUsdtAddress(data?.usdtAddress);
      setBtcAddress(data?.btcAddress);
      setEthAddress(data?.ethAddress);
      setBnbAddress(data?.bnbAddress);
      setUsdtNetwork(data?.usdtNetwork);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getPaymentDetails();
  }, []);

  const pinConfirm = async () => {
    const token = localStorage.getItem("bounty");
    setLoading(true);

    const { data } = await axios.post(
      "https://api.bountytrade.com/api/payment-details/confirm-pin",
      {
        pin,
      },
      {
        headers: { token: `Bearer ${token}` },
      }
    );

    setLoading(false);

    if (data === "Confirmed") {
      setPinModalOpen(false);
    } else {
      toast.error("Incorrect pin");
    }
  };

  const editDetails = async () => {
    const token = localStorage.getItem("bounty");
    setLoading(true);

    try {
      await axios.put(
        "https://api.bountytrade.com/api/payment-details",
        {
          btcAddress,
          ethAddress,
          usdtAddress,
          bnbAddress,
          usdtNetwork,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setDetailsChanged(true);
      setConfirmModalOpen(false);
      navigate(0);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      {pinModalOpen && <div className="modal-backdrop fade show" />}
      {pinModalOpen ? (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Input Pin</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="password"
                  value={pin}
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Checking...
                  </button>
                ) : (
                  <button className="btn btn-primary" onClick={pinConfirm}>
                    Confirm
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-content">
          <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="row">
              <div className="main-content" style={{ width: "100%" }}>
                <div className="content-area card">
                  <div className="card-innr">
                    <div className="card-head">
                      <h4 className="card-title">Payment Details</h4>
                    </div>
                    <div className="tab-content" id="profile-details">
                      <div
                        className="tab-pane fade show active"
                        id="personal-data"
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            setConfirmModalOpen(true);
                          }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="btc-address"
                                  className="input-item-label"
                                >
                                  BTC address
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="btc-address"
                                  defaultValue={btcAddress}
                                  onChange={(e) =>
                                    setBtcAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="usdt-address"
                                  className="input-item-label"
                                >
                                  USDT address
                                </label>
                                <input
                                  className="input-bordered date-picker-dob"
                                  type="text"
                                  id="usdt-address"
                                  defaultValue={usdtAddress}
                                  onChange={(e) =>
                                    setUsdtAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="eth-address"
                                  className="input-item-label"
                                >
                                  ETH address
                                </label>
                                <input
                                  className="input-bordered date-picker-dob"
                                  type="text"
                                  id="eth-address"
                                  defaultValue={ethAddress}
                                  onChange={(e) =>
                                    setEthAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="bnb-address"
                                  className="input-item-label"
                                >
                                  BNB address
                                </label>
                                <input
                                  className="input-bordered date-picker-dob"
                                  type="text"
                                  id="bnb-address"
                                  defaultValue={bnbAddress}
                                  onChange={(e) =>
                                    setBnbAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="usdt-network"
                                  className="input-item-label"
                                >
                                  USDT network
                                </label>

                                <select
                                  id="usdt-network"
                                  className="input-bordered date-picker-dob"
                                  defaultValue={usdtNetwork}
                                  onChange={(e) =>
                                    setUsdtNetwork(e.target.value)
                                  }
                                >
                                  <option value="TRC20">TRC20</option>
                                  <option value="ERC20">ERC20</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="gaps-1x" />
                          <div className="d-sm-flex justify-content-between align-items-center">
                            {loading ? (
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled
                              >
                                Updating...
                              </button>
                            ) : (
                              <button className="btn btn-primary" type="submit">
                                Update
                              </button>
                            )}
                            <div className="gaps-2x d-sm-none" />
                            {detailsChanged && (
                              <span className="text-success">
                                <em className="ti ti-check-box" /> All Changes
                                are saved
                              </span>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />

      {confirmModalOpen && <div className="modal-backdrop fade show" />}
      {confirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                style={{ border: "none", background: "transparent" }}
                onClick={() => setConfirmModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Are you sure?</h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button
                    onClick={editDetails}
                    className="btn btn-primary bg-green"
                    type="button"
                  >
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
