import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import axios from "axios";

const Withdraw = () => {
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState("BTC");
  const [amount, setAmount] = useState("");
  const [cryptoAddress, setCryptoAddress] = useState("");

  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const [firstName] = user?.name.split(" ");

  useEffect(() => {
    if (+amount && +amount <= user?.balance && cryptoAddress) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  }, [amount, cryptoAddress, user?.balance]);

  const withdraw = async () => {
    const token = localStorage.getItem("bounty");
    setLoading(true);

    try {
      await axios.post(
        `https://api.bountytrade.com/api/transaction/${user?._id}`,
        {
          user,
          amount: +amount,
          transactionType: "Withdrawal",
          asset,
          transactionId: new Date().getTime().toString(),
          dateTime: new Date(),
          cryptoAddress,
          remarks,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setReviewModalOpen(true);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-user">
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="main-content col-lg-8">
              {user?.kycStatus !== "Approved" && (
                <div className="d-lg-none">
                  <a
                    href="#/"
                    className="btn btn-danger btn-xl btn-between w-100 mgb-1-5x"
                  >
                    Complete your KYC to withdraw
                  </a>
                  <div className="gaps-1x mgb-0-5x d-lg-none d-none d-sm-block" />
                </div>
              )}
              <div className="content-area card">
                <div className="card-innr">
                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 1
                    </span>
                    <h4 className="card-title">
                      Choose crypto asset to be paid in
                    </h4>
                  </div>
                  <div className="card-text">
                    <p>
                      You can withdraw funds into using BTC, ETH, USDT or BNB
                      wallet.
                    </p>
                  </div>
                  <div className="token-currency-choose">
                    <div className="row guttar-15px">
                      <div className="col-6">
                        <div className="pay-option">
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="paybtc"
                            name="payOption"
                            defaultChecked
                            value="BTC"
                            onChange={(e) => setAsset(e.target.value)}
                          />
                          <label className="pay-option-label" htmlFor="paybtc">
                            <span className="pay-title">
                              <em className="pay-icon cf cf-btc" />
                              <span className="pay-cur">BTC</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="pay-option">
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="paybnb"
                            name="payOption"
                            value="BNB"
                            onChange={(e) => setAsset(e.target.value)}
                          />
                          <label className="pay-option-label" htmlFor="paybnb">
                            <span className="pay-title">
                              <em className="pay-icon cf cf-bnb" />
                              <span className="pay-cur">BNB</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="pay-option">
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="payeth"
                            name="payOption"
                            value="ETH"
                            onChange={(e) => setAsset(e.target.value)}
                          />
                          <label className="pay-option-label" htmlFor="payeth">
                            <span className="pay-title">
                              <em className="pay-icon cf cf-eth" />
                              <span className="pay-cur">ETH</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="pay-option">
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="payusdt"
                            name="payOption"
                            value="USDT"
                            onChange={(e) => setAsset(e.target.value)}
                          />
                          <label className="pay-option-label" htmlFor="payusdt">
                            <span className="pay-title">
                              <em className="pay-icon cf cf-usdt" />
                              <span className="pay-cur">USDT</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pay-notes" />

                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 2
                    </span>
                    <h4 className="card-title">Enter withdrawal amount</h4>
                  </div>
                  <div className="card-text">
                    <p>Enter amount to be paid into your chosen wallet</p>
                  </div>
                  <div className="token-contribute">
                    <div className="token-calc">
                      <div className="token-pay-amount">
                        <input
                          id="token-base-amount"
                          className="input-bordered input-with-hint"
                          type="number"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pay-notes" />

                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 3
                    </span>
                    <h4 className="card-title">Enter wallet address</h4>
                  </div>
                  <div className="card-text">
                    <p>
                      Simply paste the wallet address of your desired crypto
                      asset
                    </p>
                  </div>

                  <div className="input-item input-with-label mt-3">
                    <label htmlFor="address" className="input-item-label">
                      Wallet address:
                    </label>
                    <textarea
                      className="input-bordered"
                      id="address"
                      rows={1}
                      value={cryptoAddress}
                      onChange={(e) => {
                        setCryptoAddress(e.target.value);
                      }}
                    />
                  </div>
                  <div className="input-item input-with-label">
                    <label htmlFor="remarks" className="input-item-label">
                      Additional info(Optional):
                    </label>
                    <textarea
                      className="input-bordered"
                      id="remarks"
                      rows={1}
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                    />
                  </div>
                  {formCompleted && (
                    <>
                      {loading ? (
                        <button className="btn btn-primary" disabled>
                          Confirming Payment...
                        </button>
                      ) : (
                        <button className="btn btn-primary" onClick={withdraw}>
                          Confirm Payment
                        </button>
                      )}
                    </>
                  )}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="aside sidebar-right col-lg-4">
              {user?.kycStatus !== "Approved" && (
                <div className="d-none d-lg-block">
                  <a
                    href="#/"
                    className="btn btn-danger btn-xl btn-between w-100"
                  >
                    Complete your KYC to withdraw
                  </a>
                  <div className="gaps-3x" />
                </div>
              )}

              <div className="token-statistics card card-token height-auto">
                <div className="card-innr">
                  <div className="token-balance token-balance-with-icon">
                    <div className="token-balance-text">
                      <h6 className="card-sub-title">Balance</h6>
                      <span className="lead">
                        <span>$</span>
                        {user?.balance?.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="token-balance token-balance-s2">
                    <h6 className="card-sub-title">Your Contribution</h6>
                    <ul
                      className="token-balance-list"
                      style={{ justifyContent: "space-between" }}
                    >
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${user?.deposit?.toLocaleString()}
                        </span>
                        <span className="sub">DEPOSIT</span>
                      </li>
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${user?.revenue?.toLocaleString()}
                        </span>
                        <span className="sub">REVENUE</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="referral-info card">
                <div className="card-innr">
                  <h6 className="card-title card-title-sm">
                    Earn with Referral
                  </h6>
                  <p className=" pdb-0-5x">
                    Invite your friends &amp; family and receive a{" "}
                    <strong>
                      <span className="text-primary">bonus</span> of the value
                      of their investment.
                    </strong>
                  </p>
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address"
                      defaultValue={`https://dashboard.bountytrade.com/register?ref=${firstName}${user?._id}`}
                      disabled
                    />
                    <button
                      className="copy-trigger copy-clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://dashboard.bountytrade.com/register?ref=${firstName}${user?._id}`
                        );
                        toast.success("Referral link copied");
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                  </div>
                </div>
              </div>
              {user.kycStatus !== "Approved" && (
                <div className="kyc-info card">
                  <div className="card-innr">
                    <h6 className="card-title card-title-sm">
                      Identity Verification - KYC
                    </h6>
                    <p>
                      To comply with regulation, investors will have to go
                      through identity verification.
                    </p>
                    <p className="lead text-light pdb-0-5x">
                      You have not submitted your KYC application to verify your
                      identity.
                    </p>
                    <Link
                      to="/kyc-application"
                      className="btn btn-primary btn-block"
                    >
                      Click to Proceed
                    </Link>
                    <h6 className="kyc-alert text-danger">
                      * KYC verification required for certain actions
                    </h6>
                  </div>
                </div>
              )}
            </div>
            {/* .col */}
          </div>
          {/* .container */}
        </div>
        {/* .container */}
      </div>
      <Footer />

      {reviewModalOpen && <div className="modal-backdrop fade show" />}
      {reviewModalOpen && (
        <div
          className="modal fade show"
          id="pay-review"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="pay-status pay-status-success">
                  <em className="ti ti-check" />
                </div>
                <div className="gaps-2x" />
                <h3>We’re reviewing your request</h3>
                <p>
                  We’ll review your request and get back to you as soon as
                  possible.
                </p>
                <div className="gaps-2x" />
                <button
                  className="btn btn-primary bg-green"
                  type="button"
                  onClick={() => {
                    setReviewModalOpen(false);
                    navigate("/transactions");
                  }}
                >
                  Close
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Withdraw;
