import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import axios from "axios";
import Preloader from "../../components/Preloader";
import Pagination from "../../components/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminTransactions = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [tabSelected, setTabSelected] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [idToConfirm, setIdtoConfirm] = useState("");
  const [idToReject, setIdtoReject] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  const navigate = useNavigate();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = transactions?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(transactions?.length / recordsPerPage);

  useEffect(() => {
    const token = localStorage.getItem("bounty");

    const getUsers = async () => {
      const { data } = await axios.get(
        "https://api.bountytrade.com/api/transaction",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUsers();
  }, []);

  const confirmTransaction = async () => {
    const token = localStorage.getItem("bounty");
    setLoading(true);

    try {
      await axios.put(
        "https://api.bountytrade.com/api/transaction/confirm",
        {
          idToConfirm,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setConfirmModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const rejectTransaction = async () => {
    const token = localStorage.getItem("bounty");
    setLoading(true);

    try {
      await axios.put(
        "https://api.bountytrade.com/api/transaction/reject",
        {
          idToReject,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setRejectModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="content-area card">
            <div className="card-innr">
              <div className="card-head">
                <h4 className="card-title">Admin Transactions</h4>
              </div>
              <div>
                <div className="gaps-1x" />
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "All" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setTabSelected("All")}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "Deposit"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setTabSelected("Deposit")}
                    >
                      Deposit
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "Withdrawal"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setTabSelected("Withdrawal")}
                    >
                      Withdrawal
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "Credited"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setTabSelected("Credited")}
                    >
                      Credited
                    </a>
                  </li>
                </ul>
                <div className="gaps-1x" />
              </div>
              {tabSelected === "All" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-account">User</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords?.map((transaction: any) => (
                      <tr className="data-item" key={transaction._id}>
                        <td className="data-col dt-tnxno">
                          <div className="d-flex align-items-center">
                            {transaction.status === "Pending" && (
                              <div className="data-state data-state-progress">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            {transaction.status === "Rejected" && (
                              <div className="data-state data-state-canceled">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            {transaction.status === "Confirmed" && (
                              <div className="data-state data-state-approved">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            <div className="fake-class">
                              <span className="lead tnx-id">
                                #{transaction.transactionId}
                              </span>
                              <span className="sub sub-date">
                                {new Date(
                                  transaction.createdAt
                                ).toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="data-col dt-token">
                          <span className="lead token-amount">
                            {transaction?.amount?.toLocaleString()}
                          </span>
                          <span className="sub sub-symbol">USD</span>
                        </td>
                        <td className="data-col dt-amount">
                          {transaction.asset ? (
                            <span className="lead amount-pay">
                              {transaction.asset}
                            </span>
                          ) : (
                            <span className="lead amount-pay">-</span>
                          )}
                        </td>
                        <td className="data-col dt-account">
                          <span className="lead user-info">
                            {transaction?.user?.name}
                          </span>
                          <span className="sub sub-date">
                            {transaction?.user?.email}
                          </span>
                        </td>
                        {transaction.transactionType === "Deposit" && (
                          <td className="data-col dt-type">
                            <span
                              className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                              style={{ borderColor: "blue" }}
                            >
                              Deposit
                            </span>
                            <span
                              className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                              style={{ borderColor: "blue" }}
                            >
                              D
                            </span>
                          </td>
                        )}
                        {transaction.transactionType === "Withdrawal" && (
                          <td className="data-col dt-type">
                            <span
                              className="dt-type-md badge badge-outline badge-success badge-md"
                              style={{ borderColor: "orange" }}
                            >
                              Withdrawal
                            </span>
                            <span
                              className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                              style={{ borderColor: "orange" }}
                            >
                              W
                            </span>
                          </td>
                        )}
                        {transaction.transactionType === "Credited" && (
                          <td className="data-col dt-type">
                            <span className="dt-type-md badge badge-outline badge-success badge-md">
                              Credited
                            </span>
                            <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                              C
                            </span>
                          </td>
                        )}
                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              onClick={() =>
                                setTransactionId((transactionId: any) =>
                                  transactionId === transaction._id
                                    ? ""
                                    : transaction._id
                                )
                              }
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {transactionId === transaction?._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link
                                      to={`/admin-transaction/${transaction.transactionId}`}
                                    >
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                  {transaction.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoConfirm(transaction._id);
                                          setConfirmModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-check-box" />{" "}
                                        Confirm
                                      </a>
                                    </li>
                                  )}
                                  {transaction.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoReject(transaction._id);
                                          setRejectModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Reject
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {tabSelected === "All" && transactions?.length === 0 && (
                <div style={{ height: "472px" }}>
                  <p
                    className="text-xl  translate-y-[-50%]"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      top: "50%",
                    }}
                  >
                    No transactions yet
                  </p>
                </div>
              )}
              {tabSelected === "All" && transactions?.length > 10 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}

              {tabSelected === "Deposit" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-account">User</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      ?.filter(
                        (trnx: any) => trnx.transactionType === "Deposit"
                      )
                      ?.map((transaction: any) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  #{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction?.amount?.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-amount">
                            {transaction.asset ? (
                              <span className="lead amount-pay">
                                {transaction.asset}
                              </span>
                            ) : (
                              <span className="lead amount-pay">-</span>
                            )}
                          </td>
                          <td className="data-col dt-account">
                            <span className="lead user-info">
                              {transaction?.user?.name}
                            </span>
                            <span className="sub sub-date">
                              {transaction?.user?.email}
                            </span>
                          </td>
                          {transaction.transactionType === "Deposit" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                                style={{ borderColor: "blue" }}
                              >
                                Deposit
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "blue" }}
                              >
                                D
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                Withdrawal
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                W
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <div className="relative d-inline-block">
                              <button
                                onClick={() =>
                                  setTransactionId((transactionId: any) =>
                                    transactionId === transaction._id
                                      ? ""
                                      : transaction._id
                                  )
                                }
                                className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                              >
                                <em className="ti ti-more-alt" />
                              </button>
                              {transactionId === transaction?._id && (
                                <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                  <ul className="dropdown-list">
                                    <li>
                                      <Link
                                        to={`/admin-transaction/${transaction.transactionId}`}
                                      >
                                        <em className="ti ti-eye" /> View
                                        Details
                                      </Link>
                                    </li>
                                    {transaction.status === "Pending" && (
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() => {
                                            setIdtoConfirm(transaction._id);
                                            setConfirmModalOpen(true);
                                          }}
                                        >
                                          <em className="ti ti-check-box" />{" "}
                                          Confirm
                                        </a>
                                      </li>
                                    )}
                                    {transaction.status === "Pending" && (
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() => {
                                            setIdtoReject(transaction._id);
                                            setRejectModalOpen(true);
                                          }}
                                        >
                                          <em className="ti ti-na" /> Reject
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

              {tabSelected === "Withdrawal" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-account">User</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      ?.filter(
                        (trnx: any) => trnx.transactionType === "Withdrawal"
                      )
                      ?.map((transaction: any) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  #{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction?.amount?.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-amount">
                            {transaction.asset ? (
                              <span className="lead amount-pay">
                                {transaction.asset}
                              </span>
                            ) : (
                              <span className="lead amount-pay">-</span>
                            )}
                          </td>
                          <td className="data-col dt-account">
                            <span className="lead user-info">
                              {transaction?.user?.name}
                            </span>
                            <span className="sub sub-date">
                              {transaction?.user?.email}
                            </span>
                          </td>
                          {transaction.transactionType === "Deposit" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                                style={{ borderColor: "blue" }}
                              >
                                Deposit
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "blue" }}
                              >
                                D
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                Withdrawal
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                W
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <div className="relative d-inline-block">
                              <button
                                onClick={() =>
                                  setTransactionId((transactionId: any) =>
                                    transactionId === transaction._id
                                      ? ""
                                      : transaction._id
                                  )
                                }
                                className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                              >
                                <em className="ti ti-more-alt" />
                              </button>
                              {transactionId === transaction?._id && (
                                <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                  <ul className="dropdown-list">
                                    <li>
                                      <Link
                                        to={`/admin-transaction/${transaction.transactionId}`}
                                      >
                                        <em className="ti ti-eye" /> View
                                        Details
                                      </Link>
                                    </li>
                                    {transaction.status === "Pending" && (
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() => {
                                            setIdtoConfirm(transaction._id);
                                            setConfirmModalOpen(true);
                                          }}
                                        >
                                          <em className="ti ti-check-box" />{" "}
                                          Confirm
                                        </a>
                                      </li>
                                    )}
                                    {transaction.status === "Pending" && (
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() => {
                                            setIdtoReject(transaction._id);
                                            setRejectModalOpen(true);
                                          }}
                                        >
                                          <em className="ti ti-na" /> Reject
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

              {tabSelected === "Credited" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-account">User</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      ?.filter(
                        (trnx: any) => trnx.transactionType === "Credited"
                      )
                      ?.map((transaction: any) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  #{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction?.amount?.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-amount">
                            {transaction.asset ? (
                              <span className="lead amount-pay">
                                {transaction.asset}
                              </span>
                            ) : (
                              <span className="lead amount-pay">-</span>
                            )}
                          </td>
                          <td className="data-col dt-account">
                            <span className="lead user-info">
                              {transaction?.user?.name}
                            </span>
                            <span className="sub sub-date">
                              {transaction?.user?.email}
                            </span>
                          </td>
                          {transaction.transactionType === "Deposit" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                                style={{ borderColor: "blue" }}
                              >
                                Deposit
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "blue" }}
                              >
                                D
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                Withdrawal
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                W
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <div className="relative d-inline-block">
                              <button
                                onClick={() =>
                                  setTransactionId((transactionId: any) =>
                                    transactionId === transaction._id
                                      ? ""
                                      : transaction._id
                                  )
                                }
                                className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                              >
                                <em className="ti ti-more-alt" />
                              </button>
                              {transactionId === transaction?._id && (
                                <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                  <ul className="dropdown-list">
                                    <li>
                                      <Link
                                        to={`/admin-transaction/${transaction.transactionId}`}
                                      >
                                        <em className="ti ti-eye" /> View
                                        Details
                                      </Link>
                                    </li>
                                    {transaction.status === "Pending" && (
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() => {
                                            setIdtoConfirm(transaction._id);
                                            setConfirmModalOpen(true);
                                          }}
                                        >
                                          <em className="ti ti-check-box" />{" "}
                                          Confirm
                                        </a>
                                      </li>
                                    )}
                                    {transaction.status === "Pending" && (
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() => {
                                            setIdtoReject(transaction._id);
                                            setRejectModalOpen(true);
                                          }}
                                        >
                                          <em className="ti ti-na" /> Reject
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
            {/* .card-innr */}
          </div>
          {/* .card */}
        </div>
        {/* .container */}
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />

      {confirmModalOpen && <div className="modal-backdrop fade show" />}
      {confirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setConfirmModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to confirm this transaction?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Confirming...
                  </button>
                ) : (
                  <button
                    onClick={confirmTransaction}
                    className="btn btn-primary"
                  >
                    Yes, Confirm
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {rejectModalOpen && <div className="modal-backdrop fade show" />}
      {rejectModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setRejectModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to reject this transaction?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Rejecting...
                  </button>
                ) : (
                  <button
                    onClick={rejectTransaction}
                    className="btn btn-primary"
                  >
                    Yes, Reject
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTransactions;
