import { useState, FormEventHandler } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const forgotPasswordHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(
        "https://api.bountytrade.com/api/auth/forgotpassword",
        {
          email: email.toLowerCase(),
        }
      );

      if (data === "Email sent") {
        setLoading(false);
        setEmail("");
        toast.success("Email sent!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" style={{ width: "220px" }} />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">
              Forgot password{" "}
              <span>
                If you forgot your password, well then we’ll email you
                instructions to reset your password.
              </span>
            </h2>
            <form onSubmit={forgotPasswordHandler}>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Your Email"
                  className="input-bordered"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {loading ? (
                    <button
                      className="btn btn-primary btn-block"
                      type="button"
                      disabled
                    >
                      Sending Reset Link...
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block" type="submit">
                      Send Reset Link
                    </button>
                  )}
                </div>
                <div>
                  <Link to="/login">Return to login</Link>
                </div>
              </div>
              <div className="gaps-2x" />
            </form>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Terms</Link>
              </li>
              <li>© {new Date().getFullYear()} Bounty Trade.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5" />
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default ForgotPassword;
