import { useState, FormEventHandler } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const resetPassword: FormEventHandler = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      await axios.put(
        `https://api.bountytrade.com/api/auth/passwordreset/${resetToken}`,
        { password }
      );

      setLoading(false);
      toast.success("Password reset successfully!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" style={{ width: "220px" }} />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">Reset password</h2>
            <form onSubmit={resetPassword}>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="New Password"
                  className="input-bordered"
                  required
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="input-bordered"
                  required
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {loading ? (
                    <button
                      className="btn btn-primary btn-block"
                      type="button"
                      disabled
                    >
                      Resetting...
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block" type="submit">
                      Reset
                    </button>
                  )}
                </div>
              </div>
              <div className="gaps-2x" />
            </form>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Terms</Link>
              </li>
              <li>© {new Date().getFullYear()} Bounty Trade.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5" />
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default ResetPassword;
