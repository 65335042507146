import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, FormEventHandler } from "react";
import TopBar from "../components/TopBar";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Profile = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const [firstName] = user?.name.split(" ");

  useEffect(() => {
    const token = localStorage.getItem("bounty");

    const getUser = async () => {
      const { data } = await axios.get("https://api.bountytrade.com/api/user", {
        headers: { token: `Bearer ${token}` },
      });

      setName(data.name);
      setEmail(data.email);
      setPhone(data.phone);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUser();
  }, []);

  const editUser: FormEventHandler = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("bounty");

    try {
      setLoading(true);

      await axios.put(
        `https://api.bountytrade.com/api/user/${user?._id}`,
        {
          name,
          phone,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Changes saved");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const changePassword: FormEventHandler = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("bounty");

    if (newPassword.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      setNewPassword("");
      setConfirmPassword("");
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      await axios.put(
        `https://api.bountytrade.com/api/auth/changepassword/${user?._id}`,
        { oldPassword, newPassword },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Password changed!");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="main-content col-lg-8">
              <div className="content-area card">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Profile Details</h4>
                  </div>
                  <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#personal-data"
                      >
                        Personal Data
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#settings"
                      >
                        Settings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#password"
                      >
                        Password
                      </a>
                    </li>
                  </ul>
                  {/* .nav-tabs-line */}
                  <div className="tab-content" id="profile-details">
                    <div
                      className="tab-pane fade show active"
                      id="personal-data"
                    >
                      <form onSubmit={editUser}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="full-name"
                                className="input-item-label"
                              >
                                Full Name
                              </label>
                              <input
                                className="input-bordered"
                                type="text"
                                id="full-name"
                                name="full-name"
                                required
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="email-address"
                                className="input-item-label"
                              >
                                Email Address
                              </label>
                              <input
                                className="input-bordered"
                                type="text"
                                id="email-address"
                                name="email-address"
                                value={email}
                                disabled
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="mobile-number"
                                className="input-item-label"
                              >
                                Phone Number
                              </label>
                              <input
                                className="input-bordered"
                                type="text"
                                id="mobile-number"
                                name="mobile-number"
                                value={phone}
                                required
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                        </div>
                        {/* .row */}
                        <div className="gaps-1x" />
                        {/* 10px gap */}
                        {changesMade && (
                          <div className="d-sm-flex justify-content-between align-items-center">
                            {loading ? (
                              <button className="btn btn-primary" disabled>
                                Updating Profile...
                              </button>
                            ) : (
                              <button className="btn btn-primary">
                                Update Profile
                              </button>
                            )}
                          </div>
                        )}
                      </form>
                      {/* form */}
                    </div>
                    {/* .tab-pane */}
                    <div className="tab-pane fade" id="settings">
                      <div className="pdb-1-5x">
                        <h5 className="card-title card-title-sm text-dark">
                          Security Settings
                        </h5>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="save-log"
                          defaultChecked
                        />
                        <label htmlFor="save-log">Save my Activities Log</label>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="pass-change-confirm"
                        />
                        <label htmlFor="pass-change-confirm">
                          Confirm me through email before password change
                        </label>
                      </div>
                      <div className="pdb-1-5x">
                        <h5 className="card-title card-title-sm text-dark">
                          Manage Notification
                        </h5>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="latest-news"
                          defaultChecked
                        />
                        <label htmlFor="latest-news">
                          Notify me by email about sales and latest news
                        </label>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="activity-alert"
                          defaultChecked
                        />
                        <label htmlFor="activity-alert">
                          Alert me by email for unusual activity.
                        </label>
                      </div>
                      {/* <div className="gaps-1x" />
                      <div className="d-flex justify-content-between align-items-center">
                        <span />
                        <span className="text-success">
                          <em className="ti ti-check-box" /> Setting has been
                          updated
                        </span>
                      </div> */}
                    </div>
                    {/* .tab-pane */}
                    <div className="tab-pane fade" id="password">
                      <form onSubmit={changePassword}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="old-pass"
                                className="input-item-label"
                              >
                                Current Password
                              </label>
                              <input
                                className="input-bordered"
                                type="password"
                                id="old-pass"
                                name="old-pass"
                                value={oldPassword}
                                required
                                onChange={(e) => setOldPassword(e.target.value)}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          {/* .col */}
                        </div>
                        {/* .row */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="new-pass"
                                className="input-item-label"
                              >
                                New Password
                              </label>
                              <input
                                className="input-bordered"
                                type="password"
                                id="new-pass"
                                name="new-pass"
                                value={newPassword}
                                required
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          {/* .col */}
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="confirm-pass"
                                className="input-item-label"
                              >
                                Confirm New Password
                              </label>
                              <input
                                className="input-bordered"
                                type="password"
                                id="confirm-pass"
                                name="confirm-pass"
                                value={confirmPassword}
                                required
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          {/* .col */}
                        </div>
                        {/* .row */}
                        <div className="note note-plane note-info pdb-1x">
                          <em className="fas fa-info-circle" />
                          <p>Password should be at least 6 characters long.</p>
                        </div>
                        <div className="gaps-1x" />
                        {/* 10px gap */}
                        <div className="d-sm-flex justify-content-between align-items-center">
                          {loading ? (
                            <button className="btn btn-primary" disabled>
                              Updating...
                            </button>
                          ) : (
                            <button className="btn btn-primary">Update</button>
                          )}
                          {/* <div className="gaps-2x d-sm-none" />
                        <span className="text-success">
                          <em className="ti ti-check-box" /> Changed Password
                        </span> */}
                        </div>
                      </form>
                    </div>
                    {/* .tab-pane */}
                  </div>
                  {/* .tab-content */}
                </div>
                {/* .card-innr */}
              </div>
            </div>
            {/* .col */}
            <div className="col-lg-4 aside sidebar-right">
              <div className="account-info card">
                <div className="card-innr">
                  <h6 className="card-title card-title-sm">
                    Your Account Status
                  </h6>
                  <ul className="btn-grp">
                    <li>
                      <a href="#/" className="btn btn-auto btn-xs btn-success">
                        Email Verified
                      </a>
                    </li>
                    <li>
                      {user.kycStatus === "Approved" ? (
                        <a
                          href="#/"
                          className="btn btn-auto btn-xs btn-success"
                        >
                          KYC Verified
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-auto btn-xs btn-warning"
                        >
                          KYC Pending
                        </a>
                      )}
                    </li>
                  </ul>
                  <div className="gaps-2-5x" />
                </div>
              </div>
              <div className="referral-info card">
                <div className="card-innr">
                  <h6 className="card-title card-title-sm">
                    Earn with Referral
                  </h6>
                  <p className=" pdb-0-5x">
                    Invite your friends &amp; family and receive a{" "}
                    <strong>
                      <span className="text-primary">bonus</span> of the value
                      of their investment.
                    </strong>
                  </p>
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address"
                      defaultValue={`https://dashboard.bountytrade.com/register?ref=${firstName}${user?._id}`}
                      disabled
                    />
                    <button
                      className="copy-trigger copy-clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://dashboard.bountytrade.com/register?ref=${firstName}${user?._id}`
                        );
                        toast.success("Referral link copied");
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                  </div>
                </div>
              </div>
              {user.kycStatus !== "Approved" && (
                <div className="kyc-info card">
                  <div className="card-innr">
                    <h6 className="card-title card-title-sm">
                      Identity Verification - KYC
                    </h6>
                    <p>
                      To comply with regulation, investors will have to go
                      through identity verification.
                    </p>
                    <p className="lead text-light pdb-0-5x">
                      You have not submitted your KYC application to verify your
                      identity.
                    </p>
                    <Link
                      to="/kyc-application"
                      className="btn btn-primary btn-block"
                    >
                      Click to Proceed
                    </Link>
                    <h6 className="kyc-alert text-danger">
                      * KYC verification required for certain actions
                    </h6>
                  </div>
                </div>
              )}
            </div>
            {/* .col */}
          </div>
          {/* .container */}
        </div>
        {/* .container */}
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Profile;
