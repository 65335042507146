import { useState } from "react";
import logo from "../assets/logo-dark.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";

const TopBar = () => {
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const user = useSelector((state: any) => state.user.currentUser);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("bounty");
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <div className="topbar-wrap">
      <div className="topbar is-sticky">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="topbar-nav d-lg-none">
              <li className="topbar-nav-item relative">
                <a
                  className={`${
                    mobileMenuOpen ? "toggle-nav active" : "toggle-nav"
                  }`}
                  href="#/"
                  onClick={toggleMenu}
                >
                  <div className="toggle-icon">
                    <span className="toggle-line" />
                    <span className="toggle-line" />
                    <span className="toggle-line" />
                    <span className="toggle-line" />
                  </div>
                </a>
              </li>
              {/* .topbar-nav-item */}
            </ul>
            {/* .topbar-nav */}
            <Link className="topbar-logo" to="/">
              <img src={logo} alt="logo" />
            </Link>
            <ul className="topbar-nav">
              <li className="topbar-nav-item relative">
                <span className="user-welcome d-none d-lg-inline-block">
                  Welcome! {user.name}
                </span>
                <a
                  className={`${
                    profileDropdownOpen
                      ? "toggle-tigger user-thumb active"
                      : "toggle-tigger user-thumb"
                  }`}
                  href="#/"
                  onClick={toggleProfileDropdown}
                >
                  <em className="ti ti-user" />
                </a>
                <div
                  className={`${
                    profileDropdownOpen
                      ? "toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown active"
                      : "toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown"
                  }`}
                  style={{ minWidth: "200px" }}
                >
                  <div className="user-status">
                    <h6 className="user-status-title">Token balance</h6>
                    <div className="user-status-balance">
                      <small>$</small>
                      {user?.balance?.toLocaleString()}
                    </div>
                  </div>
                  <ul className="user-links">
                    <li>
                      <Link to="/profile">
                        <i className="ti ti-id-badge" />
                        My Profile
                      </Link>
                    </li>

                    <li>
                      <Link to="/activity">
                        <i className="ti ti-eye" />
                        Activity
                      </Link>
                    </li>
                  </ul>
                  <ul className="user-links bg-light">
                    <li>
                      <a href="#/" onClick={logout}>
                        <i className="ti ti-power-off" />
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {/* .topbar-nav-item */}
            </ul>
            {/* .topbar-nav */}
          </div>
        </div>
        {/* .container */}
      </div>
      {/* .topbar */}
      <div
        className={`${
          mobileMenuOpen ? "navbar navbar-mobile active" : "navbar"
        }`}
      >
        <div className="container">
          <div className="navbar-innr">
            <ul className="navbar-menu">
              <li>
                <Link to="/">
                  <em className="ikon ikon-dashboard" /> Dashboard
                </Link>
              </li>
              <li>
                <Link to="/deposit">
                  <em className="ikon ikon-coins" /> Deposit
                </Link>
              </li>
              <li>
                <Link to="/withdraw">
                  <em className="ikon ikon-distribution" /> Withdraw
                </Link>
              </li>
              <li>
                <Link to="/transactions">
                  <em className="ikon ikon-transactions" /> Transactions
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <em className="ikon ikon-user" /> Profile
                </Link>
              </li>
              {user.isAdmin && (
                <li className="has-dropdown page-links-all">
                  <a
                    className="drop-toggle"
                    href="#/"
                    onClick={toggleAccordion}
                  >
                    <em className="ikon ikon-exchange" /> Admin
                  </a>
                  <ul
                    className="navbar-dropdown"
                    style={{
                      display: `${mobileAccordionOpen ? "block" : "none"}`,
                    }}
                  >
                    <li>
                      <Link to="/users">Users</Link>
                    </li>
                    <li>
                      <Link to="/admin-transactions">Transactions</Link>
                    </li>
                    <li>
                      <Link to="/payment-details">Payment Details</Link>
                    </li>
                    <li>
                      <Link to="/kyc-list">Kyc Verification</Link>
                    </li>
                    <li>
                      <Link to="/messages">Messages</Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <ul className="navbar-btns">
              {user.kycStatus === "Approved" ? (
                <li>
                  <span className="badge badge-outline badge-success badge-lg">
                    <em className="text-success ti ti-files mgr-1x" />
                    <span className="text-success">KYC Verified</span>
                  </span>
                </li>
              ) : (
                <li>
                  <Link
                    to="/kyc-application"
                    className="btn btn-sm btn-outline btn-light"
                  >
                    <em className="text-primary ti ti-files" />
                    <span>KYC Application</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {/* .navbar-innr */}
        </div>
        {/* .container */}
      </div>
    </div>
  );
};

export default TopBar;
