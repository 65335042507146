const Pagination = ({ nPages, currentPage, setCurrentPage }: any) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <nav className="pt-3 pb-2">
      <ul className="pagination" style={{ flexWrap: "wrap" }}>
        <li
          className={`paginate_button page-item previous ${
            currentPage === 1 ? "disabled" : ""
          }`}
        >
          <a className="page-link" onClick={prevPage} href="#/">
            Prev
          </a>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`paginate_button page-item ${
              currentPage === pgNumber ? "active" : ""
            } `}
          >
            <a
              onClick={() => setCurrentPage(pgNumber)}
              className="page-link"
              href="#/"
            >
              {pgNumber}
            </a>
          </li>
        ))}
        <li
          className={`paginate_button page-item next ${
            pageNumbers.length === currentPage ? "disabled" : ""
          }`}
        >
          <a className="page-link" onClick={nextPage} href="#/">
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
