import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Preloader from "../../components/Preloader";

const AdminTransactionDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transaction, setTransaction] = useState<any>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: any) => state.user.currentUser);
  const { id } = useParams();
  const navigate = useNavigate();

  const confirmTransaction = async () => {
    const token = localStorage.getItem("bounty");
    setLoading(true);

    try {
      await axios.put(
        "https://api.bountytrade.com/api/transaction/confirm",
        {
          idToConfirm: id,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setConfirmModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const rejectTransaction = async () => {
    const token = localStorage.getItem("bounty");
    setLoading(true);

    try {
      await axios.put(
        "https://api.bountytrade.com/api/transaction/reject",
        {
          idToReject: id,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setRejectModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("bounty");

    const getTransactionDetails = async () => {
      const { data } = await axios.get(
        `https://api.bountytrade.com/api/transaction/${user?._id}/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      console.log(data);
      setTransaction(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactionDetails();
  }, [id, user?._id]);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">Transaction Details</h4>
                <Link
                  to="/admin-transactions"
                  className="btn btn-sm btn-auto btn-primary d-sm-block d-none"
                >
                  <em className="fas fa-arrow-left mr-3" />
                  Back
                </Link>
                <Link
                  to="/admin-transactions"
                  className="btn btn-icon btn-sm btn-primary d-sm-none"
                >
                  <em className="fas fa-arrow-left" />
                </Link>
              </div>
              <div className="gaps-1-5x" />
              <div className="data-details d-md-flex">
                <div className="fake-class">
                  <span className="data-details-title">Transaction Date</span>
                  <span className="data-details-info">
                    {new Date(transaction?.createdAt).toLocaleString()}
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Transaction Status</span>
                  {transaction?.status === "Confirmed" && (
                    <span className="badge badge-success ucap">Confirmed</span>
                  )}
                  {transaction?.status === "Rejected" && (
                    <span className="badge badge-danger ucap">Rejected</span>
                  )}
                  {transaction?.status === "Pending" && (
                    <span className="badge badge-warning ucap">Pending</span>
                  )}
                </div>
                <div className="fake-class">
                  <span className="data-details-title">User</span>
                  <span className="data-details-info">
                    {transaction?.user?.name}
                  </span>
                </div>
              </div>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Transaction Info</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Transaction Type</div>
                  <div className="data-details-des">
                    <strong>{transaction?.transactionType}</strong>
                  </div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Amount</div>
                  <div className="data-details-des">
                    <strong>${transaction?.amount?.toLocaleString()}</strong>
                  </div>
                </li>
                {transaction?.asset && (
                  <li>
                    <div className="data-details-head">Asset</div>
                    <div className="data-details-des">
                      <strong>{transaction?.asset}</strong>
                    </div>
                  </li>
                )}
                <li>
                  <div className="data-details-head">Transaction ID</div>
                  <div className="data-details-des">
                    <span>#{transaction?.transactionId}</span> <span />
                  </div>
                </li>
                {transaction?.remarks && (
                  <li>
                    <div className="data-details-head">Remarks</div>
                    <div className="data-details-des">
                      <span>{transaction?.remarks}</span>
                      <span />
                    </div>
                  </li>
                )}
                {transaction?.cryptoAddress && (
                  <li>
                    <div className="data-details-head">Crypto address</div>
                    <div className="data-details-des">
                      <span>{transaction?.cryptoAddress}</span>
                      <span />
                    </div>
                  </li>
                )}
              </ul>
              {/* .data-details */}
              <div className="gaps-3x" />
              {transaction?.proof && (
                <h6 className="card-sub-title">Uploaded</h6>
              )}
              {transaction?.proof && (
                <ul className="data-details-list">
                  <li>
                    <ul className="data-details-docs">
                      <li>
                        <div className="data-doc-item data-doc-item-lg">
                          <div className="data-doc-image">
                            <img src={transaction.proof} alt="doc" />
                          </div>
                          <ul className="data-doc-actions">
                            <li>
                              <a
                                href={transaction.proof}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <em className="ti ti-import" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
            </div>
          </div>
          {/* .card */}
        </div>
        {/* .container */}
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />

      {confirmModalOpen && <div className="modal-backdrop fade show" />}
      {confirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setConfirmModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to confirm this transaction?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Confirming...
                  </button>
                ) : (
                  <button
                    onClick={confirmTransaction}
                    className="btn btn-primary"
                  >
                    Yes, Confirm
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {rejectModalOpen && <div className="modal-backdrop fade show" />}
      {rejectModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setRejectModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to reject this transaction?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Rejecting...
                  </button>
                ) : (
                  <button
                    onClick={rejectTransaction}
                    className="btn btn-primary"
                  >
                    Yes, Reject
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTransactionDetails;
