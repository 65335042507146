import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

const ThankYou = () => {
  return (
    <div className="page-user">
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="card content-area">
                <div className="card-innr">
                  <div className="status status-thank px-md-5">
                    <div className="status-icon">
                      <em className="ti ti-check" />
                    </div>
                    <span className="status-text large text-dark">
                      Thank you for submitting form
                    </span>
                    <p className="px-md-5">
                      We received your data, our team will check and get back to
                      you via email.
                    </p>
                  </div>
                </div>
              </div>
              {/* .card */}
              <div className="gaps-1x" />
              <div className="gaps-3x d-none d-sm-block" />
            </div>
          </div>
        </div>
        {/* .container */}
      </div>

      <Footer />
    </div>
  );
};

export default ThankYou;
