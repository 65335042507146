import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="page-header" style={{ paddingTop: "80px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center">
              <h2 className="page-title">Privacy Policy</h2>
              <p className="large">We care about our customer privacy</p>
            </div>
          </div>
        </div>
        {/* .container */}
      </div>
      {/* .page-header */}
      <div className="page-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-9">
              <div className="card content-area">
                <div className="card-innr">
                  <div className="card-text">
                    <p>
                      <strong>
                        This page informs you of our policies regarding the
                        collection, use, and disclosure of personal data when
                        you use our Service and the choices you have associated
                        with that data.
                      </strong>
                    </p>
                    <p>
                      We use your data to provide and improve the Service. By
                      using the Service, you agree to the collection and use of
                      information in accordance with this policy. Unless
                      otherwise defined in this Privacy Policy, terms used in
                      this Privacy Policy have the same meanings as in our Terms
                      and Conditions.
                    </p>
                    <h5>
                      <strong>Information Collection And Use</strong>
                    </h5>
                    <p>
                      Every legitimate project that sources funds through an
                      investment has a website, where they specify what the
                      project is all about, its goals, the amount of money
                      needed, how long the funding campaign will go on for and
                      so forth.
                    </p>
                    <h5>
                      <strong>Types of Data Collected</strong>
                    </h5>
                    <p>
                      We collect several different types of information for
                      various purposes to provide and improve our Service to
                      you.
                    </p>
                    <h5>
                      <strong>Use of Data</strong>
                    </h5>
                    <p>
                      Bitcoin, still being the single most dominant
                      cryptocurrency, is accepted pretty much anywhere in the
                      crypto world. However, as Ethereum offers a stable and
                      convenient Blockchain platform for developers to set up
                      their projects.
                    </p>
                    <h5>
                      <strong>Transfer Of Data</strong>
                    </h5>
                    <p>
                      We may also collect information how the Service is
                      accessed and used ("Usage Data"). This Usage Data may
                      include information such as your computer's Internet
                      Protocol address (e.g. IP address), browser type, browser
                      version, the pages of our Service that you visit, the time
                      and date of your visit, the time spent on those pages,
                      unique device identifiers and other diagnostic data.
                    </p>
                  </div>
                </div>
              </div>
              {/* .card */}
              <div className="gaps-1x" />
              <div className="gaps-3x d-none d-sm-block" />
            </div>
          </div>
        </div>
        {/* .container */}
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
