import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

const EmailSent = () => {
  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" style={{ width: "220px" }} />
            </Link>
          </div>
          <div className="page-ath-text">
            <h2 className="page-ath-heading">
              Thank you!{" "}
              <small>Your registration process is almost done.</small>{" "}
              <span className="text-success">
                Please check your mail and verify.
              </span>
            </h2>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Terms</Link>
              </li>
              <li>© {new Date().getFullYear()} Bounty Trade.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSent;
