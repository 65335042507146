import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

const KycCompleted = () => {
  return (
    <div className="page-user">
      <TopBar />

      <div className="page-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-9">
              <div className="kyc-status card mx-lg-4">
                <div className="card-innr">
                  <div className="status status-thank px-md-5">
                    <div className="status-icon">
                      <em className="ti ti-check" />
                    </div>
                    <span className="status-text large text-dark">
                      You have completed the KYC process
                    </span>
                    <p className="px-md-5">
                      We are still waiting for your identity verification. Once
                      our team verifies your indentity, you will be notified by
                      email.
                    </p>
                  </div>
                </div>
              </div>
              {/* .card */}
              <p className="text-light text-center mgmt-1x">
                If you have any question, please contact our support team{" "}
                <a href="mailto:contact@bountytrade.com">
                  contact@bountytrade.com
                </a>
              </p>
              <div className="gaps-1x" />
              <div className="gaps-3x d-none d-sm-block" />
            </div>
          </div>
        </div>
        {/* .container */}
      </div>
      <Footer />
    </div>
  );
};

export default KycCompleted;
